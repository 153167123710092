import {
    EacCodeUseTypeEnum,
    GlobalRegion,
    reportHubComponents,
    TrackingInstrumentType,
    ZoneDataAccessLevel,
} from "./";
import { EnergySource } from "./enum";
import { paths } from "./schemas/report-hub-api-schema";

export type IKind<T> = {
    kind: T;
};

export enum ReportHubFeatureFlag {}

export namespace ReportHubDto {
    export type ContractOption = {
        contractId: string;
        name: string;
    };

    export type GetContractsResponse = Array<ContractOption>;

    //TODO review if can remove this, might be legacy
    export type ContractTrackingKpis = {
        interval_Wh: number;
        intervalPpa_Wh: number;
        intervalGreenTariff_Wh: number;
        billing_Wh: number;
        billingPpa_Wh: number;
        billingGreenTariff_Wh: number;
        eacsReceived_Wh: number;
        eacsAllocated_Wh: number;
        eacsPendingAllocation_Wh: number;
    };

    export type ContractTrackingKpisPpa = reportHubComponents["schemas"]["ContractTrackingKpisPpa"];

    export type ContractTrackingOverviewPpaRow =
        reportHubComponents["schemas"]["ContractTrackingOverviewPpaRow"];

    export type ContractTrackingOverviewPpa =
        reportHubComponents["schemas"]["ContractTrackingOverviewPpa"];

    export type ContractTrackingKpisGreenTariff =
        reportHubComponents["schemas"]["ContractTrackingKpisGreenTariff"];
    export type ContractTrackingOverviewGreenTariffRow =
        reportHubComponents["schemas"]["ContractTrackingOverviewGreenTariffRow"];

    export type ContractTrackingOverviewGreenTariff =
        reportHubComponents["schemas"]["ContractTrackingOverviewGreenTariff"];

    export type ContractTrackingKpisUnbundled =
        reportHubComponents["schemas"]["ContractTrackingKpisUnbundled"];

    export type ContractTrackingOverviewUnbundledRow =
        reportHubComponents["schemas"]["ContractTrackingOverviewUnbundledRow"];

    export type ContractTrackingOverviewUnbundled =
        reportHubComponents["schemas"]["ContractTrackingOverviewUnbundled"];

    export type ContractTrackingMonthlyPpaRow =
        reportHubComponents["schemas"]["ContractTrackingMonthlyPpaRow"];

    export type ContractTrackingMonthlyGreenTariffRow =
        reportHubComponents["schemas"]["ContractTrackingMonthlyGreenTariffRow"];

    export type TrackingInstrumentAssignment =
        reportHubComponents["schemas"]["TrackingInstrumentAssignment"];

    export type Region = {
        regionId: string;
        name: string;
    };
    export type GetRegions = Array<Region>;

    export type GetContractsPpa = Array<ContractOptionPpa>;
    export type GetContractsGreenTariff = Array<ContractOption>;

    export type ContractOptionPpa = {
        productionSitesIds: Array<string>;
        contractId: string;
        name: string;
    };

    export type ContractTrackingMonthlyPpa = Array<ContractTrackingMonthlyPpaRow>;
    export type ContractTrackingMonthlyGreenTariff = Array<ContractTrackingMonthlyGreenTariffRow>;

    export type Allocation = reportHubComponents["schemas"]["AllocationV2"];

    export enum GetAllocationsOrderBy {
        UploadDate = "uploadDate",
        ConsumptionOrganizationName = "consumptionOrganizationName",
        TransactionFromName = "transactionFromName",
        AllocatedTo = "allocatedTo",
    }

    export enum RE100Contribution {
        FullContribution = "full-contribution",
        PartialContribution = "partial-contribution",
        NoContribution = "no-contribution",
        NoContractInfo = "no-contract-info",
    }

    export type AllocationAttestation = {
        transactionId: string;
        uploadDate: Date;
        allocatedTo: string | null;
        transactionFromName: string;
        consumptionOrganizationName?: string;
        consumptionPeriodStart: Date;
        consumptionPeriodEnd: Date;
        trackingInstrumentType: TrackingInstrumentType.Attestation;
        scheme: string | null;
        productionSiteName?: string;
    };

    export type AllocationCancellation = {
        transactionId: string;
        uploadDate: Date;
        allocatedTo: string | null;
        transactionFromName: string;
        consumptionOrganizationName?: string;
        consumptionPeriodStart: Date;
        consumptionPeriodEnd: Date;
        trackingInstrumentType: TrackingInstrumentType.EAC;
        productionSiteId: string;
        productionSiteName: string;
        consumptionSiteId: string | null;
        productionPeriodStart: Date;
        productionPeriodEnd: Date;
    };

    export type GetAllocationsByTenantIdResponse = {
        allocations: Array<Allocation>;
        totalAllocations: number;
    };

    export type SourcingMethod = reportHubComponents["schemas"]["SourcingMethod"];

    export type LocalContract = reportHubComponents["schemas"]["LocalContract"];

    export type UpdateLocalContractPayload =
        reportHubComponents["schemas"]["UpdateLocalContractPayload"];

    export type EacCode = {
        tenantId: string;
        eacCodeId: number;
        eacCode: string;
        registryId: string;
        useType: EacCodeUseTypeEnum;
    };

    export type Country = {
        countryId: string;
        name: string;
        enabled: boolean;
        passiveProcurement: boolean;
    };
    export type CountryName = reportHubComponents["schemas"]["CountryName"];

    export type BiddingZone = {
        zoneId: string;
        countryId: string;
        name: string;
        timezones: Array<string>;
        enabled: boolean;
        dataAccess: ZoneDataAccessLevel;
        regionId: string;
    };

    export type Market = {
        marketId: string;
        name: string;
        description: string;
    };

    export type AnnualRegionEmissionFactor =
        reportHubComponents["schemas"]["AnnualRegionEmissionFactor"];

    export type AssignmentDetail = reportHubComponents["schemas"]["AssignmentDetail"];

    export enum CountryAssignmentsSortField {
        SourcingMethod = "sourcingMethod",
        AssignedWh = "assignedWh",
        EnergySourceId = "energySourceId",
        EacSchemeId = "eacSchemeId",
        ProductionCountryName = "productionCountryName",
        ProductionPeriodStart = "productionPeriodStart",
        ProductionSiteCommissioningDate = "productionSiteCommissioningDate",
        ContractStartTimeLocal = "contractStartTimeLocal",
    }

    export type CountryAssignmentsResponse =
        paths["/{tenantId}/assignment/country-assignment"]["get"]["responses"]["200"]["content"]["application/json"];

    export type ContractTesting =
        paths["/admin/contracts/{contractId}"]["get"]["responses"]["200"]["content"]["application/json"];

    export type CountryAssignmentKpis = {
        countryId: string;
        countryName: string;
        consumptionWh: number;
        assignedWh: number;
        locationBasedEmissionsT: number;
        marketBasedEmissionsT: number;
        passiveProcurement: boolean;
        globalRegionId: GlobalRegion;
        regionIds: Array<string>;
    };

    export type GetReportingKpisResponse = {
        totals: {
            consumptionWh: number;
            assignedWh: number;
            locationBasedEmissionsT: number;
            marketBasedEmissionsT: number;
        };
        byCountry: Array<CountryAssignmentKpis>;
    };

    export type Attestation = {
        attestationId?: string;
        attestationDocumentId?: number;
        consumptionOrganizationName?: string | null;
        consumptionPeriodEnd?: string;
        consumptionPeriodStart?: string;
        consumptionTimezone?: string;
        eacSchemeId?: string | null;
        percentageCoverage?: number;
        productionSiteCommissioningDate?: string | null;
        productionSiteName?: string | null;
        tenantId?: string;
        transactionFrom?: string;
        transactionId?: string;
        quantityWh?: number;
    };

    export type Registry = reportHubComponents["schemas"]["Registry"];

    export type AllocationFilters = {
        transactionIds: Array<string>;
        productionSiteNames: Array<string | null>;
        allocatedToNames: Array<string | null>;
        productionCountries: Array<string | null>;
        energySources: Array<EnergySource | null>;
        registries: Array<Registry | null>;
    };

    export type GlobalOverviewKpis = {
        consumption: {
            totalWh: number;
            trackingInstrumentCoverage: number;
        };
        volumes: {
            totalWh: number;
            ppaWh: number;
            greenTariffWh: number;
            unbundledWh: number;
        };
        trackingInstruments: {
            totalWh: number;
            eacCancelledWh: number;
            eacTotalWh: number;
            attestationTotalWh: number;
        };
    };

    export type GlobalOverviewCountryCoverageDetail = {
        tenantId: string;
        countryId: string;
        countryName: string;
        consumptionWh: number;
        purchasedVolumeWh: number;
        trackingInstrumentWh: number;
        coveredShare: number;
        uncoveredWh: number;
    };
    export type GlobalOverviewCountriesCoverage = {
        countriesCoverage: Array<GlobalOverviewCountryCoverageDetail>;
    };

    export type GlobalOverviewV2Kpis = reportHubComponents["schemas"]["GlobalOverviewV2Kpis"];
    export type GlobalOverviewV2CountryDetail =
        reportHubComponents["schemas"]["GlobalOverviewV2PerformanceByCountryDetail"];
    export type GlobalOverviewV2CountriesPerformance = {
        countriesPerformance: Array<GlobalOverviewV2CountryDetail>;
    };

    export type GlobalOverviewV2GroupDetail =
        reportHubComponents["schemas"]["GlobalOverviewV2PerformanceByGroupDetail"];
    export type GlobalOverviewV2GroupsPerformance = {
        groupsPerformance: Array<GlobalOverviewV2GroupDetail>;
    };

    export type GetCountries = Array<Country>;

    export type ReportingPeriod = reportHubComponents["schemas"]["ReportingPeriod"];
    export type GetReportingPeriods = Array<ReportingPeriod>;

    export type GlobalOverviewMonthSiteAggStringifiedBigInts =
        reportHubComponents["schemas"]["GlobalOverviewMonthSiteAgg"];

    export type GlobalOverviewTransactionStringifiedBigInts =
        reportHubComponents["schemas"]["GlobalOverviewTransaction"];

    export type GlobalOverviewSiteAgg = Omit<
        GlobalOverviewMonthSiteAggStringifiedBigInts,
        | "consumptionWh"
        | "coverageWh"
        | "notCoveredWh"
        | "excessWh"
        | "billingGreenTariffWh"
        | "billingPpaWh"
        | "billingVppaWh"
        | "billingUnbundledWh"
    > & {
        consumptionWh: bigint;
        coverageWh: bigint;
        notCoveredWh: bigint;
        excessWh: bigint;
        billingGreenTariffWh: bigint;
        billingPpaWh: bigint;
        billingVppaWh: bigint;
        billingUnbundledWh: bigint;
    };
}
