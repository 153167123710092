import { currencyMap, TwentyFourSevenDto } from "@flexidao/dto";
import { formatNumber } from "./format-number";

export const getContractCurrency = (contract: TwentyFourSevenDto.Contract | null): string => {
    return contract?.currency ?? "";
};

export const getCurrencySymbol = (currencyIsoCode: string): string => {
    const currencySymbol = currencyMap.get(currencyIsoCode)?.symbol;
    if (!currencySymbol) {
        throw new Error(`Symbol not found for iso code '${currencyIsoCode}'`);
    }

    return currencySymbol;
};

export const formatCurrency = (
    value: number,
    currencyIsoCode: string,
    numDecimals?: number,
): string => {
    const currencySymbol = getCurrencySymbol(currencyIsoCode);

    return `${formatNumber(value, numDecimals)}${currencySymbol}`;
};
